@import "assets/styles/bootstrap_overrides.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "assets/styles/buttons.scss";
@import "assets/styles/event-editor.scss";
@import "assets/styles/text.scss";

.error,
.required {
    color: red;
}

body {
    font-family: $primary-font;
}